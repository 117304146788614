import React from "react";

export default function Main(){

    return(
        <div className={'main_wrapper'} id={'home'}>
            {/*<div className={style.blue_box}></div>*/}
            <p className={'header_title'}>
                Hi there!<br/> I am Marta,<br/>
                Frontend Developer.
            </p>
        </div>
    )
}